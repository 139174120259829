<template>
    <sidebar/>
</template>
<script>
import { Sidebar } from './components'
export default {
  name: 'Layout',
  components: {
    Sidebar
  }
}
</script>
<style scoped>

</style>
